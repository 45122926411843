define(function(require) {
    "use strict";

    //#import
    var Backbone = require('backbone');

    //@implementation

    /**
     * MainNavigationItemModel
     *
     * @author alexander.claes
     *
     * @constructor
     */
    function MainNavigationItemModel() {

        /**
         * Instance
         * @type {Object}
         */
        var instance = {};

        /**
         * Default properties
         * @type {Object}
         */
        instance.defaults = {
            active: false,
            hasSubItems: false
        };

        /**
         * Toggle the model's active state
         */
        instance.toggle = function() {
            this.set('active', !this.get('active'));
        };

        /**
         * Set the model's active state true
         */
        instance.activate = function() {
            this.set('active', true);
        };

        /**
         * Set the model's active state false
         */
        instance.deactivate = function() {
            this.set('active', false);
        };

        var staticfunc = {
            trackNavLink: function(){
                // Enter tracking code here if necessary
            }
        };
        var Model = Backbone.Model.extend(instance, staticfunc);

        return new Model();
    }

    return MainNavigationItemModel;
    //@end
});
