define(function(require) {
    "use strict";

    //#import
    var Backbone = require('backbone');

    //@implementation

    /**
     * MetaDataModel
     *
     * @author alexander.claes
     *
     * @constructor
     */
    function MetaDataModel() {

        /**
         * Instance
         * @type {Object}
         */
        var instance = {};

        /**
         * Default properties
         * @type {Object}
         */
        instance.defaults = {
            currentSection: 1,
            sectionCount: 1,
            resultCount: null,
            initialEmpty: false,
            overlay: false,
            listCollection: null,
            loadMoreCollection: null
        };

        /**
         * Set the model's active state false
         */
        instance.addSection = function() {
            var count = this.get('sectionCount');
            this.set('sectionCount', count+1);
        };

        /**
         * Set the model's active state false
         */
        instance.nextSection = function(count) {
            count = count || 1;
            var section = this.get('currentSection');
            if (section < this.get('sectionCount')) {
                this.set('currentSection', section+count);
            }
        };

        /**
         * Set the model's active state false
         */
        instance.prevSection = function(count) {
            count = count || 1;
            var section = this.get('currentSection') - count || 1;
            if(parseInt(section) && section > 0){
                this.set('currentSection', section);
            }
        };

        var Model = Backbone.Model.extend(instance);

        return new Model();
    }

    return MetaDataModel;
    //@end
});
