define(function(require) {
   "use strict";

   //#import
   var Backbone = require('backbone');
   var MetaDataModel = require('Eis/ListingModule/Model/MetaDataModel');

   //@implementation

    /**
     * MetaDataCollection
     *
     * @author marvin.maj
     *
     * @constructor
     */
   function MetaDataCollection() {

        /**
         * Instance
         * @type {Object}
         */
       var instance = {};

        /**
         * Type of models this collection contains
         * @type {Object}
         */
       instance.model = MetaDataModel;

       var Collection = Backbone.Collection.extend(instance);

       return new Collection();
   }

   return MetaDataCollection;
   //@end
});