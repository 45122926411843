define(function(require) {
    "use strict";

    //#import
    var _ = require('underscore');
    var GoogleAnalyticsService = require('Jvm/TrackingModule/Service/GoogleAnalyticsService');
    var GoogleTagManagerService = require('Jvm/TrackingModule/Service/GoogleTagManagerService');

    //@implementation

    /**
     * TrackingService
     *
     * @author alexander.claes
     *
     * @constructor
     */
    var TrackingService = function() {


        /**
         * Instance
         *
         * @type {object}
         */
        var instance = {};

        /**
         * List of all used services
         *
         * @private
         * @type {array}
         */
        var services = [];

        // Singleton constructor
        TrackingService = function TrackingService() {
            return instance;
        };

        /**
         * Init
         */
        function init() {

            // Push references to uses tracking services to this array
            services.push(GoogleAnalyticsService);
            services.push(GoogleTagManagerService);

        }

        /**
         * Check if all services available.
         *
         * @returns {boolean}
         */
        instance.servicesAvailable = function() {
            var available = true;

            _.each(services, function(service) {
                if(service && service.serviceAvailable && !service.serviceAvailable())  {
                    available = false;
                }
            });

            return available;
        };

        /**
         * Track a (virtual) pageview
         * @param  {String} url The url that should be tracked as a page
         */
        instance.trackPageview = function(url){

            // Iterate over all services to call their interface
            _.each(services, function(service){
                if(service && service.trackPageview){
                    service.trackPageview.call(service, url);
                }
            }, this);
        };

        instance.push = function(data) {
            if(data) {
                _.each(services, function(service) {
                    if(service && service.push){
                        service.push.call(service, data);
                    }
                }, this);
            }
        };

        /**
         * Track an event
         * @param  {String} category       The name you supply for the group of objects you want to track.
         * @param  {String} action         The type of user interaction
         * @param  {String} label          (Optional) Provide additional dimensions to the event data
         * @param  {Integer} value         (Optional) Provide numerical data about the user event
         * @param  {Object} additionalData (Optional) Key-value Pair containing additional data specific for some services
         */
        instance.trackEvent = function(category, action, label, value, additionalData) {

            // Iterate over all services to call their interface
            _.each(services, function(service){
                if(service && service.trackEvent){
                    service.trackEvent.call(service, category, action, label, value, additionalData);
                }
            }, this);

        };

        /**
         * Track an event for GA4
         * @param  {String} type           The type of the event, e.g. "Gerneric Event", "Ecommerce - Add to cart"
         * @param  {String} name       The name of the event.
         * @param  {Object} parameters (Optional) Key-value Pair containing additional data
         * @param  {String} location        (Optional) The location the event was triggerd e.g "category page"

        instance.trackGA4Event = function(type, name, parameters, location) {

            // Iterate over all services to call their interface
            _.each(services, function(service){
                if(service && service.trackGA4Event){
                    service.trackGA4Event.call(service, type, name, parameters, location);
                }
            }, this);

        };
         */

        //Call Init
        init.call(this);

        return instance;
    };

    return new TrackingService();
    //@end
});
