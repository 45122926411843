import { registerDynamicView } from 'Jvm/CoreModule/Factory/ViewFactory'; 

export function init() {
    registerDynamicView('Login', () => import('./View/LoginView'), {
        loadOptions: {
            delay: 1500
        }
    });
    registerDynamicView('LoginMobile', () => import('./View/LoginMobileView'), {
        loadOptions: {
            delay: 1500
        }
    });
}
