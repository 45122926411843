const $ = require('jquery');
const _ = require('underscore');
const Backbone = require('backbone');
const CoreEvent = require('Jvm/CoreModule/Event/CoreEvent');

const dynamicViews = {};
const dynamicModulesLoaded = new Set();

/**
 * Cached view elements
 *
 * @private
 * @type {object}
 */
let cachedViewElements = {};

/**
 * All known view types
 *
 * @private
 * @type {object}
 */
const views = {};

const viewsPending = new Set;

/**
 * All initialized views
 * @type {Object}
 */
const activeViews = {};

let $body = null;

export function init() {
    // Subscribe to DOM update event
    CoreEvent.on(CoreEvent.DOM_UPDATE, onDomUpdate);
    CoreEvent.on(CoreEvent.DOM_REMOVE, onDomRemove);

    $body = $(document.body);
    cacheViewElements($body);
    onDomUpdate( { moduleScope: $body });
}

/**
 * Initialize new views upon DOM Update
 * @param  {object} data given with the DOM update event
 */
function onDomUpdate(data) {
    if (data.moduleScope) {
        initViews(data.moduleScope);
    }
}

/**
 * Remove active views upon DOM remove
 * @param  {object} data given with the DOM update event
 */
function onDomRemove(data) {
    if (data.moduleScope) {
        destroyViews(data.moduleScope);
    }
}

export function registerDynamicView(viewId, createImportFn, {
    viewParameters,
    loadOptions
} = {}) {

    if (views[viewId] || dynamicViews[viewId]) {
        console.warn(`View ${viewId} already registered`);
        return;
    }

    loadOptions = Object.assign({
        type: 'in-dom',
        delay: 0,
    }, loadOptions)

    const config = {
        viewId,
        viewParameters,
        createImportFn,
        loadOptions,
        loadModule,
    }

    function loadModule(moduleScope) {
        viewsPending.add(viewId);
        loadDynamicModule(createImportFn, function (viewModule) {
            create(moduleScope, viewModule.default, viewParameters);
            viewsPending.delete(viewId);
            if(viewsPending.size === 0) {
                CoreEvent.trigger(CoreEvent.DOM_DYNAMIC_VIEWS_LOADED);
            }
        }, loadOptions.delay);
    }

    dynamicViews[viewId] = config;
    if (loadOptions.type === 'initial') {
        $(loadModule);
    } else if(loadOptions.type === 'in-dom' && isViewInCachedViewElements(viewId)) {
        loadModule();
    }
}

function loadDynamicModule(loadAsyncModuleFn, callback, delay) {
    setTimeout(() => {
        loadAsyncModuleFn().then((module) => {
            if (!dynamicModulesLoaded.has(module)) {
                dynamicModulesLoaded.add(module);
                callback(module)
            }
        });
    }, delay || 0)
}

function cacheViewElements(rootElement) {
    var $rootElement = $(rootElement);
    var allViews = $rootElement.find(`[data-view]:not([data-cid])`);
    allViews = allViews.toArray();
    if ($rootElement.attr('data-view') && !$rootElement.attr('data-cid')) {
        allViews.unshift($rootElement[0]);
    }
    cachedViewElements = _.reduce(allViews, (map, viewEl) => {
        var viewElements = map[viewEl.dataset.view] || [];
        map[viewEl.dataset.view] = viewElements;
        viewElements.push(viewEl);
        return map;
    }, {});
}

function isViewInCachedViewElements(viewId) {
    return !!cachedViewElements[viewId];
}

function loadModulesOfViewsInDOM(moduleScope) {
    _.each(dynamicViews, (config, viewId) => {
        const isNotLoaded = !views[viewId];
        if (config.loadOptions.type === 'in-dom' && isNotLoaded && isViewInCachedViewElements(viewId)) {
            config.loadModule(moduleScope);
        }
    });
}

/**
 * Init all views with a certain module scope
 * @param  {string} moduleScope The module's scope
 */
function initViews(moduleScope) {
    if (moduleScope) {
        cacheViewElements($body);
        loadModulesOfViewsInDOM(moduleScope);
        _.each(views, (view) => {
            initView(moduleScope, view.view, view.parameters);
        });
    }
}

function destroyViews(moduleScope) {
    if (moduleScope) {
        moduleScope.find('[data-cid]').each(function () {
            const cid = this.dataset.cid;
            const view = cid && activeViews[cid];
            if (view) {
                if (view.destroy) {
                    view.destroy.call(view);
                }
                view.undelegateEvents();
                view.$el.removeAttr('data-cid');
                view.$el.removeData().unbind();
                view.stopListening();
                activeViews[cid] = null;
                delete activeViews[cid];
            }
        });
    }
}

/**
 * Initialize a view
 * @param  {string} moduleScope    The module's scope this view will be in
 * @param  {object} view           The view
 * @param  {array} viewParameters  An array of parameters/dependencies that will be passed on view init (optional)
 */
function initView(moduleScope, view, viewParameters = []) {
    // Check all required parameters
    if (view && view.id && view.object) {
        const viewElements = cachedViewElements[view.id];
        _.each(viewElements, function (element) {
            const $element = $(element);
            const theView = new view.object(...viewParameters);
            theView.viewName = view.id + 'View';
            const BackboneView = Backbone.View.extend(_.extend({
                options: {
                    element: $element,
                    scope: moduleScope
                }
            }, theView));
            const concreteView = new BackboneView();
            $element.attr('data-cid', concreteView.cid);
            activeViews[concreteView.cid] = concreteView;
        });
    }
}

/**
 * Store a view and create a instance
 * @param  moduleScope
 * @param  {object} view           The view'
 * @param  {null|array} viewParameters An array of parameters/dependencies that will be passed on view init (optional)
 */
function create(moduleScope = $body, view, viewParameters) {

    if (view) {
        const viewId = view.id;
        if (!viewId || views[viewId]) {
            return;
        }

        // Create view config
        views[viewId] = {
            view: view,
            parameters: viewParameters
        };

        initView(moduleScope, view, viewParameters);
    }
}
