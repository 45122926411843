define(function(require) {
    "use strict";

    //#import
    var Backbone = require('backbone');
    var MainNavigationItemModel = require('Eis/MainNavigationModule/Model/MainNavigationItemModel');

    //@implementation

    /**
     * MainNavigationContainerItemCollection
     *
     * @author alexander.claes
     *
     * @constructor
     */
    function MainNavigationContainerItemCollection() {

        /**
         * Instance
         * @type {Object}
         */
        var instance = {};

        /**
         * Type of models this collection contains
         * @type {Object}
         */
        instance.model = MainNavigationItemModel;

        var Collection = Backbone.Collection.extend(instance);

        return new Collection();
    }

    return MainNavigationContainerItemCollection;
    //@end
});
