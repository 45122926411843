import { registerDynamicView } from 'Jvm/CoreModule/Factory/ViewFactory'; 
import TvChannelCollection from './Collection/TvChannelCollection';
import TvVideoCollection from './Collection/TvVideoCollection';

export function init() {
    const tvChannelCollection = new TvChannelCollection();
    const tvVideoCollection = new TvVideoCollection();
    registerDynamicView('TvController', () => import('./View/TvControllerView'), {
        viewParameters: [tvVideoCollection, tvChannelCollection]
    });
    registerDynamicView('TvVideoPlayer', () => import('./View/TvVideoPlayerView'), {
        viewParameters: [tvVideoCollection]
    });
    registerDynamicView('TvChannelItem', () => import('./View/TvChannelItemView'), {
        viewParameters: [tvVideoCollection]
    });
    registerDynamicView('TvChannel', () => import('./View/TvChannelView'), {
        viewParameters: [tvChannelCollection]
    });
    registerDynamicView('TvChannelSwitch', () => import('./View/TvChannelSwitchView'), {
        viewParameters: [tvChannelCollection]
    });
}
