define(function(require) {
    "use strict";

    //#import
    var Backbone = require('backbone');
    var MainNavigationBlockModel = require('Eis/MainNavigationModule/Model/MainNavigationBlockModel');

    //@implementation

    /**
     * MainNavigationContainerBlockCollection
     *
     * @author alexander.claes
     *
     * @constructor
     */
    function MainNavigationContainerBlockCollection() {

        /**
         * Instance
         * @type {Object}
         */
        var instance = {};

        /**
         * Type of models this collection contains
         * @type {Object}
         */
        instance.model = MainNavigationBlockModel;

        var Collection = Backbone.Collection.extend(instance);

        return new Collection();
    }

    return MainNavigationContainerBlockCollection;
    //@end
});
