define(function(require) {
    "use strict";

    //#import
    var Backbone = require('backbone');
    var _ = require('underscore');

    //@implementation

    /**
     * CartEvent
     *
     * @author carina.grabke, manuel.puchta
     */
    var CartEvent = _.extend({}, Backbone.Events);

    /**
     * Fired when user interacts with products at cart overview
     * @type {String}
     * @constant
     */
    CartEvent.CART_STARTUPDATE = 'cart:startUpdate';

    /**
     * Fired when product is added to cart
     * @type {String}
     * @constant
     */
    CartEvent.CART_UPDATE = 'cart:update';

    /**
     * Fired when product is added to cart successfully
     * @type {String}
     * @constant
     */
    CartEvent.CART_SUCCESS = 'cart:success';

    /**
     * Fired when adding product to cart failed
     * @type {String}
     * @constant
     */
    CartEvent.CART_FAIL = 'cart:fail';

    /**
     * Fired when product is added to cart
     * @type {String}
     * @constant
     */
    CartEvent.KLARNA_RELOAD = 'klarna:reload';

    /**
     * Fired when user views checkout
     * @type {String}
     * @constant
     */
    CartEvent.SHOW_CHECKOUT = 'cart:showCheckout';

    return CartEvent;

});
