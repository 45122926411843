define(function(require) {
    "use strict";

    //#import
    var $ = require('jquery');
    var _ = require('underscore');
    var StickyConstant = require('Eis/StickyElementsModule/Constant/StickyConstant');

    //@implementation

    /**
     * StickyService
     *
     * @author alexander.claes
     *
     * @constructor
     */
    var StickyService = function() {


        /**
         * Instance
         *
         * @type {object}
         */
        var instance = {};

        /**
         * Collection
         *
         * @type {object}
         */
        var collection = null;

        // Singleton constructor
        StickyService = function StickyService() {
            return instance;
        };

        /**
         * Init
         */
        function init() {
        }

        /**
         * Get an elements current sate based an his model
         * @param  {String} id The id correspondig to [data-sticky-identifiert] attribute
         * @return {String}    The current state as defined in StickyConstant
         */
        function getElementsState(id){
            var result;

            var model = collection.where({
                'id': id
            })[0];

            if(model){
                result = model.get('state')
            }

            return result;
        }

        instance.defineCollection = function(obj){
            collection = obj;
        }

        /**
         * Get current state of a specified element, that may be sticky
         * @param  {String} id The id correspondig to [data-sticky-identifiert] attribute
         * @return {String}    The current state as defined in StickyConstant
         */
        instance.getState = function(id){
            return getElementsState(id);
        }

        /**
         * Check if an element has default behaviour
         * @param  {String} id The id correspondig to [data-sticky-identifiert] attribute
         * @return {Boolean}
         */
        instance.hasBehaviourDefault = function(id){
            return getElementsState(id) === StickyConstant.DEFAULT;
        }

        /**
         * Check if an element has sticky behaviour
         * @param  {String} id The id correspondig to [data-sticky-identifiert] attribute
         * @return {Boolean}
         */
        instance.hasBehaviourSticky = function(id){
            return getElementsState(id) === StickyConstant.STICKY;
        }

        /**
         * Check if an element has contain behaviour
         * @param  {String} id The id correspondig to [data-sticky-identifiert] attribute
         * @return {Boolean}
         */
        instance.hasBehaviourContain = function(id){
            return getElementsState(id) === StickyConstant.CONTAIN;
        }

        //Call Init
        init.call(this);

        return instance;
    };

    return new StickyService();
    //@end
});
