define(function(require) {
    "use strict";

    //#import
    var Backbone = require('backbone');
    var FilterSelectedModel = require('Eis/ListingModule/Model/FilterSelectedModel');

    //@implementation

    /**
     * FilterCollection
     *
     * @author alexander.claes
     *
     * @constructor
     */
    function FilterSelectionCollection() {

        /**
         * Instance
         * @type {Object}
         */
        var instance = {};

        /**
         * Type of models this collection contains
         * @type {Object}
         */
        instance.model = FilterSelectedModel;

        var Collection = Backbone.Collection.extend(instance);

        return new Collection();
    }

    return FilterSelectionCollection;
    //@end
});
