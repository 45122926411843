define(function(require) {
    "use strict";

    //#import
    var Backbone = require('backbone');
    var PartialLoadModel = require('Eis/ApplicationModule/Model/PartialLoadModel');

    //@implementation

    function PartialLoadCollection() {
        
        var instance = {};

        instance.model = PartialLoadModel;

        var Collection = Backbone.Collection.extend(instance);

        return new Collection();
    }

    return PartialLoadCollection;
    //@end
});