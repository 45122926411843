define(function(require) {
    "use strict";

    //#import
    var Backbone = require('backbone');

    //@implementation

    /**
     * FilterModel
     *
     * @author alexander.claes
     *
     * @constructor
     */
    function FilterModel() {

        /**
         * Instance
         * @type {Object}
         */
        var instance = {};

        /**
         * Default properties
         * @type {Object}
         */
        instance.defaults = {
            name: null,
            value: null,
            label: null,
        };

        var Model = Backbone.Model.extend(instance);

        return new Model();
    }

    return FilterModel;
    //@end
});
