import { registerDynamicView } from 'Jvm/CoreModule/Factory/ViewFactory'; 

export function init() {
    registerDynamicView('Checkbox', () => import('./View/CheckboxView'));
    registerDynamicView('RadioGroup', () => import('./View/RadioGroupView'));
    registerDynamicView('SelectBox', () => import('./View/SelectBoxView'));
    registerDynamicView('SectionToggle', () => import('./View/SectionToggleView'));
    registerDynamicView('Rating', () => import('./View/RatingView'));
    registerDynamicView('ChainedFields', () => import('./View/ChainedFieldsView'));
    registerDynamicView('InputFieldMinMax', () => import('./View/InputFieldMinMaxView'));
    registerDynamicView('AddressSuggestions', () => import(/* webpackMode: "eager" */ './View/AddressSuggestionsView'));
    registerDynamicView('ServerFormValidation', () => import(/* webpackMode: "eager" */ './View/ServerFormValidation'));
}
