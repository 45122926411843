define(function(require) {
    "use strict";

    //#import
    var Backbone = require('backbone');

    //@implementation

    /**
     * MetaDataModel
     *
     * @author carina.grabke
     *
     * @constructor
     */
    function MetaDataModel() {

        /**
         * Instance
         * @type {Object}
         */
        var instance = {};

        /**
         * Default properties
         * @type {Object}
         */
        instance.defaults = {
            hasMore: false,
            currentCount: 0
        };

        var Model = Backbone.Model.extend(instance);

        return new Model();
    }

    return MetaDataModel;
    //@end
});
