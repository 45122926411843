import { registerDynamicView } from 'Jvm/CoreModule/Factory/ViewFactory';
import MainNavigationLinkListCollection from './Collection/MainNavigationLinkListCollection';
import MainNavigationEvent from 'Eis/MainNavigationModule/Event/MainNavigationEvent';

export function init() {
    MainNavigationEvent.once(
        [MainNavigationEvent.MAINNAVIGATION_TOGGLE, MainNavigationEvent.MAINNAVIGATION_OPEN].join(' '),
        () => {
            const mainNavigationLinkListCollection = new MainNavigationLinkListCollection();
            registerDynamicView('MainNavigationLinkList', () => import('./View/MainNavigationLinkListView'), {
                viewParameters: [mainNavigationLinkListCollection]
            });
            registerDynamicView('MainNavigationLinkListToggle', () => import('./View/MainNavigationLinkListToggleView'), {
                viewParameters: [mainNavigationLinkListCollection]
            });
        }
    );
}
