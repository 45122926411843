define(function(require) {
    "use strict";

    //#import
    var $ = require('jquery');
    var _ = require('underscore');
    var ScrollEvent = require('Jvm/ScrollModule/Event/ScrollEvent');
    var BreakpointConstant = require('Jvm/ViewportModule/Constant/BreakpointConstant');
    var BreakpointService = require('Jvm/ViewportModule/Service/BreakpointService');
    var ClientUtility = require('Jvm/CoreModule/Utility/ClientUtility');

    //@implementation

    /**
     * ScrollService
     *
     * @author matthias.rohmer
     *
     * @constructor
     */
    var ScrollService = function() {

        /**
         * Instance
         *
         * @type {object}
         */
        var instance = {};

        /**
         * reference to document selector
         *
         * @type {object}
         */
        var elWindow = $(window);

        /**
         * Marker to fire start srolling event
         * @type {Boolean}
         */
        var scrollStarted = false;

        /**
         * Current scroll position
         * @type {Integer}
         */
        var scrollPosition;

        /**
         * Timer to fire stop scrolling event
         */
        var timer;

        /**
         * Deplay time for throttle scrolling
         * @type {float}
         */
        var throttleDelay = 1000 / 4;

        // Singleton constructor
        ScrollService = function ScrollService() {
            return instance;
        };

        /**
         * Init
         */
        function init() {
            saveScrollPosition();
            elWindow.scroll(_.throttle(fire, throttleDelay));
        }

        /**
         * Update and fire event
         */
        function fire() {

            // Mark and fire start scrolling
            if (!scrollStarted) {
                scrollStarted = true;
                ScrollEvent.trigger(ScrollEvent.SCROLL_START);
            }

            // Clear timer
            if (timer) {
                clearTimeout(timer);
            }

            // Trigger scrolling
            saveScrollPosition();
            ScrollEvent.trigger(ScrollEvent.SCROLL);

            // Mark and fire stop scrolling
            timer = setTimeout(function() {
                scrollStarted = false;
                ScrollEvent.trigger(ScrollEvent.SCROLL_STOP);
            }, 350);
        }

        function saveScrollPosition() {
            ClientUtility.readDom(function() {
                scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
            });
        }

        function getHeaderOffset(){
            var offset = 0;
            var offsetElement = 'header';
            var $offsetElement;

            if(BreakpointService.isBreakpointEqualTo(BreakpointConstant.ONE)){
                offsetElement = 'header-mobile';
            }

            $offsetElement = $('[data-scrollto-offset="'+offsetElement+'"]');

            if($offsetElement && $offsetElement.length){
                offset = $offsetElement.height();
            }

            if(offset < 0){
                offset = 0;
            }

            return offset;
        }

        instance.getScrollPosition = function() {
            return scrollPosition;
        };

        instance.goToScrollPosition = function(position, respectHeaderOffset) {
            if (position || position === 0) {

                if(respectHeaderOffset){
                    position -= getHeaderOffset();
                }

                if(position < 0){
                    position = 0;
                }

                elWindow.scrollTop(position);
            }
        };

        //Call Init
        init.call(this);

        return instance;
    };

    return new ScrollService();
    //@end
});
