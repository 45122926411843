define(function (require) {
    "use strict";

    const _ = require("underscore");
    const $ = require("jquery");

    const BASE_ELEMENT_CLASS = "js-server-validation-view";
    const DEBOUNCE_IN_MS = 1000;

    function ServerFormValidationView() {
        let $el;
        let $form;
        let config;
        let fields;
        let targetElements;
        let abortController;
        let needsValidation = true;
        let isValid;

        function update() {
            updateTargets();
            updateFields();
        }

        function updateFields() {
            const canValidate = isValidatable();
            fields.forEach((field) => {
                if (canValidate) {
                    setStatusToElement(
                        field.inputElement,
                        field.isValid,
                        field.errorClass,
                        field.successClass
                    );
                } else {
                    if (field.errorClass) {
                        field.inputElement.classList.remove(field.errorClass);
                    }

                    if (field.successClass) {
                        field.inputElement.classList.remove(field.successClass);
                    }
                }
            });
        }

        function updateTargets() {
            const errorClass = getConfig().targetErrorClass;
            const successClass = getConfig().targetSuccessClass;
            if ((!errorClass && !successClass) || !targetElements) return;
            const canValidate = isValidatable();
            targetElements.forEach((target) => {
                if (canValidate) {
                    setStatusToElement(
                        target,
                        isValid,
                        errorClass,
                        successClass
                    );
                } else {
                    if (errorClass) {
                        target.classList.remove(errorClass);
                    }

                    if (successClass) {
                        target.classList.remove(successClass);
                    }
                }
            });
        }

        function setStatusToElement(
            element,
            isValid,
            errorClass,
            successClass
        ) {
            if (isValid) {
                if (errorClass) {
                    element.classList.remove(errorClass);
                }

                if (successClass) {
                    element.classList.add(successClass);
                }
            } else {
                if (errorClass) {
                    element.classList.add(errorClass);
                }

                if (successClass) {
                    element.classList.remove(successClass);
                }
            }
        }

        function fetchValidation() {
            if (abortController) {
                abortController.abort();
            }

            abortController = new AbortController();

            const values = getFieldValues();
            const src = getConfig().src;

            return fetch(src, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    values,
                }),
                signal: abortController.signal,
            }).then((response) => response.json(), onFetchValidationError);
        }

        function onFetchValidationError(error) {
            console.log(error);
        }

        function initFields() {
            fields = [];
            getConfig().options.forEach((option) => {
                const inputElements = $form[0].querySelectorAll(
                    option.inputSelector
                );
                Array.from(inputElements).forEach((inputElement) => {
                    initField(inputElement, option);
                });
            });
        }

        function initTargets() {
            const targetSelector = getConfig().targetSelector;
            const errorClass = getConfig().errorClass;
            if (targetSelector && errorClass) {
                targetElements = Array.from(
                    $form[0].querySelectorAll(targetSelector)
                );
                addClassToElements(targetElements, BASE_ELEMENT_CLASS);
            }
        }

        function addClassToElements(elements, styleClass) {
            elements.forEach((element) => element.classList.add(styleClass));
        }

        function initField(inputElement, option) {
            const name = option.name || inputElement.name;
            const field = {
                ...option,
                inputElement,
                value: inputElement.value,
                name,
            };

            inputElement.classList.add(BASE_ELEMENT_CLASS);
            $(inputElement).on(field.validateOn, (event) =>
                onFieldValidation(event, field)
            );

            fields.push(field);
        }

        function onFieldValidation(event, field) {
            if (field.value === field.inputElement.value && !needsValidation) {
                return;
            }

            field.value = field.inputElement.value;
            needsValidation = true;

            setTimeout(() => {
                if (isValidatable()) {
                    validateDebounce(field);
                } else {
                    update();
                }
            });
        }

        function isValidatable() {
            let canValidate = true;
            fields.forEach((field) => {
                const inputElementClassList = field.inputElement.classList;
                const isNotDisabled = !field.inputElement.disabled;
                canValidate = canValidate && isNotDisabled;

                if (field.validateIfHasClass) {
                    canValidate &&= inputElementClassList.contains(
                        field.validateIfHasClass
                    );
                }

                if (field.validateIfHasNoClass) {
                    canValidate &&= !inputElementClassList.contains(
                        field.validateIfHasNoClass
                    );
                }
            });

            return canValidate;
        }

        function validate() {
            isValid = false;
            return fetchValidation().then((validationResponse) => {
                const validation = validationResponse || {};
                let allValid = true;
                fields.forEach((iterField) => {
                    const fieldStatus = !!validation[iterField.name];
                    allValid = allValid && fieldStatus;
                    iterField.isValid = fieldStatus;
                });
                isValid = allValid;
                needsValidation = false;
                update();
            });
        }

        const validateDebounce = _.debounce(validate, DEBOUNCE_IN_MS, false);

        function getFieldValues() {
            return fields.reduce((acc, field) => {
                return {
                    ...acc,
                    [field.name]: field.inputElement.value,
                };
            }, {});
        }

        function getConfigFromDOM() {
            const src = getAttributeValue($el[0], "src");
            const validateOn =
                getAttributeValue($el[0], "validate-on") || "input";
            const errorClass =
                getAttributeValue($el[0], "error-class") ||
                "server-validation-error";
            const successClass =
                getAttributeValue($el[0], "success-class") ||
                "server-validation-success";
            const targetErrorClass = getAttributeValue(
                $el[0],
                "target-error-class"
            );
            const targetSuccessClass = getAttributeValue(
                $el[0],
                "target-success-class"
            );
            const isHint = $el[0].hasAttribute("is-hint");
            const targetSelector = getAttributeValue($el[0], "target-selector");

            const queryContainer = $el[0].content || $el[0];
            const optionElements = queryContainer.querySelectorAll("option");
            const options = [];

            for (const optionElement of Array.from(optionElements)) {
                const optionInputSelector = getAttributeValue(
                    optionElement,
                    "input-selector"
                );

                if (!optionInputSelector) {
                    continue;
                }

                const optionName = getAttributeValue(optionElement, "name");
                const optionErrorClass = getAttributeValue(
                    optionElement,
                    "error-class"
                );
                const optionSuccessClass = getAttributeValue(
                    optionElement,
                    "success-class"
                );
                const optionIfHasNoClass = getAttributeValue(
                    optionElement,
                    "validate-if-has-no-class"
                );
                const optionIfHasClass = getAttributeValue(
                    optionElement,
                    "validate-if-has-class"
                );
                const optionValidateOn =
                    getAttributeValue(optionElement, "validate-on") ||
                    validateOn;

                options.push({
                    name: optionName,
                    inputSelector: optionInputSelector,
                    errorClass: optionErrorClass,
                    successClass: optionSuccessClass,
                    validateIfHasClass: optionIfHasClass,
                    validateIfHasNoClass: optionIfHasNoClass,
                    validateOn: optionValidateOn,
                });
            }

            return {
                src,
                errorClass,
                successClass,
                targetErrorClass,
                targetSuccessClass,
                targetSelector,
                isHint,
                options,
            };
        }

        this.initialize = function () {
            $el = this.options.element;
            initForm();
            initFields();
            initTargets();

            if (isValidatable()) {
                validate();
            }
        };

        function initForm() {
            $form = $el.closest("form");
        }

        function getAttributeValue(element, key) {
            return (element.getAttribute(key) || "").trim();
        }

        function getConfig() {
            if (!config) {
                config = getConfigFromDOM();
            }

            return config;
        }
    }

    return {
        id: "ServerFormValidation",
        object: ServerFormValidationView,
    };
    //@end
});
