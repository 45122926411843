define(function(require) {
    "use strict";

    //#import

    //@implementation

    /**
     * StickyConstant
     *
     * @author alexander.claes
     */
    var StickyConstant = {};

    /**
     * Default behaviour for sticky elements
     * @type {String}
     * @constant
     */
    StickyConstant.DEFAULT = 'sticky:default';

    /**
     * Sticky element is sticky/fixed
     * @type {String}
     * @constant
     */
    StickyConstant.STICKY = 'sticky:sticky';

    /**
     * Sticky element is sticky/fixed with an container
     * @type {String}
     * @constant
     */
    StickyConstant.CONTAIN = 'sticky:contain'

    return StickyConstant;

});
