define(function(require) {
    "use strict";

    //#import
    var _ = require('underscore');

    //@implementation

    /**
     * GoogleAnalyticsService
     *
     * @author alexander.claes
     *
     * @constructor
     */
    var GoogleAnalyticsService = function() {


        /**
         * Instance
         *
         * @type {object}
         */
        var instance = {};

        /**
         * Breakpoints
         *
         * @private
         * @type {object}
         */
        var services = {};

        // Singleton constructor
        GoogleAnalyticsService = function GoogleAnalyticsService() {
            return instance;
        };

        /**
         * Init
         */
        function init() {

        }

        /**
         * Test if the tracking service's API is available
         * @return {Boolen} True, if the API is available
         */
        function apiAvailable(){

            return typeof ga !== 'undefined';
        }

        /**
         * Check if service available.
         *
         * @returns {boolean}
         */
        instance.serviceAvailable = function() {
            return (
                typeof window.ga !== 'undefined'
                && ga.loaded
            );
        };

        /**
         * Track a (virtual) pageview
         * @param  {String} url The url that should be tracked as a page
         */
        instance.trackPageview = function(url){

            if(apiAvailable()){

                // url is necessary
                if(url && url.length){

                     // Send pageview request to Google Analytics API
                    ga('send', 'pageview', url);
                }
            }
        };

        /**
         * Track an event
         * @param  {String} category       The name you supply for the group of objects you want to track.
         * @param  {String} action         The type of user interaction
         * @param  {String} label          (Optional) Provide additional dimensions to the event data
         * @param  {Integer} value         (Optional) Provide numerical data about the user event
         * @param  {Object} additionalData (Optional) Key-value Pair containing additional data specific for some services
         */
        instance.trackEvent = function(category, action, label, value, additionalData) {

            if(apiAvailable()){

                 // category and action are necessary
                if(category && category.length && action && action.length){

                    // Build gaq tracking object
                    var eventObj = {
                         'hitType': 'event',
                         'eventCategory':  category,
                         'eventAction': action
                    };

                    // Label is optional
                    if(label && label.length){
                        eventObj['eventLabel'] = label;
                    }

                    // Make sure value is an integer
                    var valueInt = parseInt(value);
                    if(valueInt){
                        eventObj['eventValue'] = valueInt;
                    }

                    // Optional additional data
                    if(additionalData){

                        // Google Analytic specific nonInteraction flag
                        if(_.has(additionalData, 'nonInteraction')){
                           eventObj['nonInteraction'] = additionalData.noninteraction;
                        }
                    }

                    // Send event request to Google Analytics API
                    ga('send', 'event', eventObj);

                }

            }
        };

        //instance.on = registerObserver;

        //Call Init
        init.call(this);

        return instance;
    };

    return new GoogleAnalyticsService();
    //@end
});
