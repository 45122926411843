import { registerDynamicView } from 'Jvm/CoreModule/Factory/ViewFactory';

import AjaxParameterModel from './Model/AjaxListParameterModel';
import MetaDataModel from './Model/AjaxListMetaDataModel';
import PartialLoadCollection from './Collection/PartialLoadCollection';

export function init() {

    const ajaxParameterModel = new AjaxParameterModel();
    const metaDataModel = new MetaDataModel();
    const partialLoadCollection = new PartialLoadCollection();

    registerDynamicView('Application', () => import('./View/ApplicationView'));
    registerDynamicView('AjaxForm', () => import('./View/AjaxFormView'));
    registerDynamicView('AjaxFormPackstation', () => import('./View/AjaxFormPackstationView'));
    registerDynamicView('AjaxFormPackstationCheckout', () => import('./View/AjaxFormPackstationCheckoutView'));
    registerDynamicView('PartialLoad', () => import('./View/PartialLoadView'), {
        viewParameters: [partialLoadCollection]
    });
    registerDynamicView('AjaxList', () => import('./View/AjaxListView'), {
        viewParameters: [ajaxParameterModel, metaDataModel]
    });
}
