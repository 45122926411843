import { registerDynamicView } from 'Jvm/CoreModule/Factory/ViewFactory'; 
import VoucherItemCollection from './Collection/VoucherItemCollection';

export function init() {
    const voucherItemCollection = new VoucherItemCollection();
    registerDynamicView('VoucherItem', () => import('./View/VoucherItemView'), {
        viewParameters: [voucherItemCollection]
    });
    registerDynamicView('VoucherUse', () => import('./View/VoucherUseView'));
}
