define(function(require) {
    "use strict";

    //#import
    var $ = require('jquery');

    //@implementation

    /**
     * DataConfigUtility
     *
     * @author alexander.claes
     *
     * @constructor
     */
    var DataConfigUtility = function() {

        /**
         * Instance
         *
         * @type {object}
         */
        var instance = {};

        // Singleton constructor
        DataConfigUtility = function DataConfigUtility() {
            return instance;
        };

        /**
         * Parse a string with object literal syntax to get an data object
         * @param  {string}  dataString A string with object literal syntax
         * @return {null|object} Object if parsing was successful; otherwise null
         */
        instance.parseDataString = function(dataString) {
            var result = null;

            // Replace single quotes with double quotes
            if (typeof dataString === 'string') {
                dataString = dataString.replace(/\'/g, '"');
            }

            // Catch JSON parse errors
            try {
                result = JSON.parse(dataString);

            } catch (e) {
                // console.log(e);
            }

            return result;
        };

        instance.transformEmptyStringToUndefined = function(obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key) && obj[key] === '') {
                    obj[key] = undefined;
                }
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    instance.transformEmptyStringToUndefined(obj[key]);
                }
            }
        };

        instance.parseFormData = function(form) {
            var formdata = form.serializeArray();
            var jsonData = {};

            $.map(formdata, function(n, i) {
                if (jsonData[n.name]) {
                    if (typeof(jsonData[n.name]) === 'object') {
                        jsonData[n.name].push(n.value);
                    } else {
                        jsonData[n.name] = [jsonData[n.name], n.value];
                    }
                } else {
                    jsonData[n.name] = n.value;
                }
            });

            return jsonData;
        };

        instance.updateQueryString = function(key, value, url) {
            if (!url) url = window.location.href;
            var re = new RegExp("([?&])" + key + "=.*?(&|#|$)(.*)", "gi"),
                hash;

            if (re.test(url)) {
                if (typeof value !== 'undefined' && value !== null)
                    return url.replace(re, '$1' + key + "=" + value + '$2$3');
                else {
                    hash = url.split('#');
                    url = hash[0].replace(re, '$1$3').replace(/(&|\?)$/, '');
                    if (typeof hash[1] !== 'undefined' && hash[1] !== null)
                        url += '#' + hash[1];
                    return url;
                }
            }
            else {
                if (typeof value !== 'undefined' && value !== null) {
                    var separator = url.indexOf('?') !== -1 ? '&' : '?';
                    hash = url.split('#');
                    url = hash[0].replace(/(&|\?)$/, '') + separator + key + '=' + value;
                    if (typeof hash[1] !== 'undefined' && hash[1] !== null)
                        url += '#' + hash[1];
                    return url;
                }
                else
                    return url;
            }
        };

        instance.getQueryParameter = function(key, url) {
            if (typeof(url) === 'undefined') {
                url = window.location.href;
            }
            var match = url.match('[?&]' + key + '=([^&]+)');
            return match ? match[1] : null;
        };

        return instance;
    };

    return new DataConfigUtility();
    //@end
});
