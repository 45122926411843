define(function(require) {
    "use strict";

    //#import
    var $ = require('jquery');
    var _ = require('underscore');
    var BreakpointConstant = require('Jvm/ViewportModule/Constant/BreakpointConstant');
    var BreakpointService = require('Jvm/ViewportModule/Service/BreakpointService');
    var DataConfigUtility = require('Jvm/CoreModule/Utility/DataConfigUtility');

    //@implementation

    /**
     * GoogleTrakkenService
     *
     * This service implements tracking with Google Analytics (GA4).
     * The concept is designed by Trakken and can be accessed with an account from Trakken: https://guide.trakken.de/
     *
     * @constructor
     */
    var GoogleTrakkenService = function() {

        /**
         * Instance
         *
         * @type {object}
         */
        var instance = {};

        instance.ADD_TO_CART_EVENT = {};
        instance.ADD_TO_CART_EVENT.TYPE = 'Ecommerce - Add to cart';
        instance.ADD_TO_CART_EVENT.NAME = 'add_to_cart';

        instance.VIEW_CART_EVENT = {};
        instance.VIEW_CART_EVENT.TYPE = 'Ecommerce - View cart';
        instance.VIEW_CART_EVENT.NAME = 'view_cart';

        instance.REMOVE_FROM_CART_EVENT = {};
        instance.REMOVE_FROM_CART_EVENT.TYPE = 'Ecommerce - Remove from cart';
        instance.REMOVE_FROM_CART_EVENT.NAME = 'remove_from_cart';

        instance.START_CHECKOUT_EVENT = {};
        instance.START_CHECKOUT_EVENT.TYPE = 'Ecommerce - Begin Checkout';
        instance.START_CHECKOUT_EVENT.NAME = 'begin_checkout';

        instance.SELECT_ITEM_EVENT = {};
        instance.SELECT_ITEM_EVENT.TYPE = 'Ecommerce - Select Item';
        instance.SELECT_ITEM_EVENT.NAME = 'select_item';

        instance.VIEW_ITEM_EVENT = {};
        instance.VIEW_ITEM_EVENT.TYPE = 'Ecommerce - View Item Event';
        instance.VIEW_ITEM_EVENT.NAME = 'view_item';

        instance.SELECT_PROMOTION_EVENT = {};
        instance.SELECT_PROMOTION_EVENT.TYPE = 'Ecommerce - Select promotion';
        instance.SELECT_PROMOTION_EVENT.NAME = 'select_promotion';

        instance.VIEW_PROMOTION_EVENT = {};
        instance.VIEW_PROMOTION_EVENT.TYPE = 'Ecommerce - Promotion view';
        instance.VIEW_PROMOTION_EVENT.NAME = 'view_promotion';

        instance.CART_EVENT_LOCATION_PRODUCT_DETAIL_PAGE = 'product detail page';
        instance.CART_EVENT_LOCATION_CATEGORY_PAGE = 'category page';
        instance.CART_EVENT_LOCATION_SHOPPING_PAGE = 'shopping cart';
        instance.CART_EVENT_LOCATION_HOME_PAGE = 'Homepage';

        instance.GENERIC_EVENT = {};
        instance.GENERIC_EVENT.TYPE = 'Generic Event';
        instance.GENERIC_EVENT.NAME_NAVIGATION_HEADER = 'navigation_header';
        instance.GENERIC_EVENT.NAME_NAVIGATION_FOOTER = 'navigation_footer';
        instance.GENERIC_EVENT.NAME_NAVIGATION_SHOP = 'navigation_shop';
        instance.GENERIC_EVENT.NAME_NAVIGATION_BURGER_MENU = 'navigation_burger_menu';
        instance.GENERIC_EVENT.NAME_SOCIAL_CLICK = 'social_click';
        instance.GENERIC_EVENT.NAME_OUTBOUND_CLICK = 'outbound_click';
        instance.GENERIC_EVENT.NAME_SCROLL_MAN = 'scroll_manually';
        instance.GENERIC_EVENT.NAME_SCROLL_AUTO = 'scroll_automatically';

        instance.GENERIC_EVENT.NAME_FILTER_SEXUAL_ORIENTATION = 'filter_sexual_orientation';
        instance.GENERIC_EVENT.NAME_FILTER_COLOR = 'filter_color';
        instance.GENERIC_EVENT.NAME_FILTER_PRICE_MAX = 'filter_price_max';
        instance.GENERIC_EVENT.NAME_FILTER_PRICE_MIN = 'filter_price_min';
        instance.GENERIC_EVENT.NAME_FILTER_RATING = 'filter_rating';
        instance.GENERIC_EVENT.NAME_FILTER_BRAND = 'filter_brand';
        instance.GENERIC_EVENT.NAME_FILTER_MATERIAL = 'filter_material';
        instance.GENERIC_EVENT.NAME_FILTER_SIZE = 'filter_size';
        instance.GENERIC_EVENT.NAME_FILTER_DIAMETER = 'filter_diameter';
        instance.GENERIC_EVENT.NAME_FILTER_LENGTH = 'filter_length';
        instance.GENERIC_EVENT.NAME_FILTER_TAG = 'filter_tag';

        instance.GENERIC_EVENT.NAME_DOWNLOAD = 'downloads';
        instance.GENERIC_EVENT.INTERNAL_SEARCH = 'internal_search';

        instance.GENERIC_EVENT.NAME_OVERLAY = 'overlays';

        instance.GENERIC_EVENT.NAME_ADD_TO_WISHLIST = 'add_to_wishlist';
        instance.GENERIC_EVENT.NAME_REMOVE_FROM_WISHLIST = 'remove_from_wishlist';

        instance.VIRTUAL_PAGE_VIEW_EVENT = {};
        instance.VIRTUAL_PAGE_VIEW_EVENT.TYPE = 'Virtual Page View';

        instance.JS_ERROR_EVENT = {};
        instance.JS_ERROR_EVENT.TYPE = 'Error - Javascript';
        instance.JS_ERROR_EVENT.NAME = 'error_javascript';

        // Singleton constructor
        GoogleTrakkenService = function GoogleTrakkenService() {
            return instance;
        };

        /**
         * Init
         */
        function init() {
        }

        /**
         * Test if the tracking service's API is available
         *
         * @return {Boolean} True, if the API is available
         */
        function apiAvailable(){
            return typeof dataLayer !== 'undefined';
        }

        /**
         * Check if service available.
         *
         * @returns {boolean}
         */
        instance.serviceAvailable = function() {
            return typeof window.google_tag_manager !== 'undefined';
        };

        /**
         * Push data to dataLayer
         *
         * @param  {Object} data The data which should be push to the Tag Managers dataLayer
         */
        instance.push = function(data){
            if(apiAvailable()){
                DataConfigUtility.transformEmptyStringToUndefined(data);
                dataLayer.push(data);
            }
        };

        /**
         * Track an event for GA4
         *
         * @param  {String} type            The type of the event, e.g. "Generic Event", "Ecommerce - Add to cart"
         * @param  {String} name            The name of the event.
         * @param  {Object} parameters      (Optional) Key-value Pair containing additional data
         * @param  {String} location        (Optional) The location the event was triggered e.g "category page"
         */
        instance.trackEvent = function(type, name, parameters, location) {
            // type and name are necessary
            if(!type || !name){
                return;
            }

            // Build tracking object
            var eventObj = {
                'event': type,
                'event_name': name,
                // parameters

                //  event_category: 'Category Value',
                //       event_action: 'Action Value',
                //       event_label: 'Label Value',
                //       event_value: 'Event Value'
            };

            if(parameters){
                eventObj[name] = parameters;

                if(location){
                    var eventLocation = name + '_location';
                    eventObj[name][eventLocation] = location;
                }
            }


            // Send event request to Google Analytics API
            instance.push(eventObj);
        };

        /**
         * Track an event for GA4 which is already defined.
         *
         * Some checks are executed to prevent false tracking data.
         *
         * @param  {Object} eventData    Complete GA4 event object
         */
        instance.trackPreparedEvent = function(eventData) {

            if(typeof eventData === 'undefined'){
                // TODO: Errorhandler
                //console.log("No eventData given for tracking.");
                return;
            }

            if(typeof eventData.event !== 'string' || eventData.event === ''){
                // TODO: Errorhandler
                //console.log("No event given for tracking.");
                return;
            }

            if(typeof eventData.event_name !== 'string' || eventData.event_name === ''){
                // TODO: Errorhandler
                //console.log("No event_name given for tracking.");
                return;
            }

            if(typeof eventData[eventData.event_name] !== 'object'){
                // TODO: Errorhandler
                //console.log("No event parameters given for tracking.");
                return;
            }

            // Send event request to Google Analytics API
            instance.push(eventData);
        };

        instance.trackBurgerNavigation = function(){
            var type = instance.GENERIC_EVENT.TYPE;
            var name = instance.GENERIC_EVENT.NAME_NAVIGATION_BURGER_MENU;
            var trackingData = {
                navigation_bar: 'burger_menu_mobile',
                navigation_item: undefined,
                event_category: 'navigation',
                event_action: 'burger_menu_mobile',
                event_label: undefined
            };
            instance.trackEvent(type, name, trackingData);
        };

        instance.trackNavigationLevel1 = function(navItem){
            var name, trackingData, type = instance.GENERIC_EVENT.TYPE;

            // Remove line breaks greedy over many lines
            navItem = navItem.replace(/(\r\n|\n|\r)/gm, "");

            if(BreakpointService.isBreakpointEqualTo(BreakpointConstant.ONE)){
                name = instance.GENERIC_EVENT.NAME_NAVIGATION_BURGER_MENU;
                trackingData = {
                    navigation_bar: 'burger_menu_mobile_level_1',
                    navigation_item: navItem,
                    event_category: 'navigation',
                    event_action: 'burger_menu_mobile_level_1',
                    event_label: navItem
                };
            } else {
                name = instance.GENERIC_EVENT.NAME_NAVIGATION_HEADER;
                trackingData = {
                    navigation_bar: 'top_level_1',
                    navigation_item: navItem,
                    event_category: 'navigation',
                    event_action: 'top_level_1',
                    event_label: navItem
                };
            }

            instance.trackEvent(type, name, trackingData);
        };

        instance.trackNavigationLevel2And3 = function(item, level){
            var name, type = instance.GENERIC_EVENT.TYPE;

            if(BreakpointService.isBreakpointEqualTo(BreakpointConstant.ONE)) {
                name = instance.GENERIC_EVENT.NAME_NAVIGATION_BURGER_MENU;
                level = 'burger_menu_mobile_level_' + level;
            } else {
                name = instance.GENERIC_EVENT.NAME_NAVIGATION_HEADER;
                level = 'top_level_' + level;
            }

            var trackingData = {
                navigation_bar: level,
                navigation_item: item,
                event_category: 'navigation',
                event_action: level,
                event_label: item
            };

            instance.trackEvent(type, name, trackingData);
        };

        instance.trackWishlistEvent = function(wishlistData){
            var error = false, name = 'unknown', trackingData = {};
            var type = instance.GENERIC_EVENT.TYPE;

            var location = instance.getPageLocationFromDom();
            if(typeof location === 'undefined'){
                location = 'unknown';
            }

            if(wishlistData.action === 'add'){
                name = instance.GENERIC_EVENT.NAME_ADD_TO_WISHLIST;
                try {
                    trackingData = {
                        product_id: wishlistData.trackGA4.items[0].item_id,
                        price: wishlistData.trackGA4.items[0].price,
                        event_category: 'wishlist',
                        event_action: 'add product',
                        event_label: wishlistData.trackGA4.items[0].item_id
                    };
                } catch (e) {
                    error = true;
                }

            } else if(wishlistData.action === 'delete'){
                name = instance.GENERIC_EVENT.NAME_REMOVE_FROM_WISHLIST;
                try {
                    trackingData = {
                        product_id: wishlistData.trackGA4.items[0].item_id,
                        price: wishlistData.trackGA4.items[0].price,
                        event_category: 'wishlist',
                        event_action: 'remove product',
                        event_label: wishlistData.trackGA4.items[0].item_id
                    };
                } catch (e) {
                    error = true;
                }
            }
            else {
                error = true;
            }

            if (!error){
                instance.trackEvent(type, name, trackingData, location);
            }
        };

        /**
         * Track footer navigation event for GA4.
         *
         * @param  {string} item Category and name of the link the user clicked on
         */
        instance.trackFooter = function(item) {
            var type = instance.GENERIC_EVENT.TYPE;
            var name = instance.GENERIC_EVENT.NAME_NAVIGATION_FOOTER;

            var trackingData = {
                navigation_bar: 'footer',
                navigation_item: item,
                event_category: 'navigation',
                event_action: 'footer',
                event_label: item
            };

            instance.trackEvent(type, name, trackingData);
        };

        /**
         * Track navigation-shop event
         *
         * @param  {string} item Category and name of the link the user clicked on
         */
        instance.trackNavigationShop = function(item) {
            var type = instance.GENERIC_EVENT.TYPE;
            var name = instance.GENERIC_EVENT.NAME_NAVIGATION_SHOP;

            var trackingData = {
                navigation_bar: 'shop',
                navigation_item: item,
                event_category: 'navigation',
                event_action: 'shop',
                event_label: item
            };

            instance.trackEvent(type, name, trackingData);
        };

        /**
         * Track click on social network
         *
         * @param  {string} networkName Name of the social network
         * @param  {string} url         URL of the link
         */
        instance.trackSocialClick = function(networkName, url) {
            var type = instance.GENERIC_EVENT.TYPE;
            var name = instance.GENERIC_EVENT.NAME_SOCIAL_CLICK;

            var trackingData = {
                social_network: networkName,
                event_category: 'social click',
                event_action: url,
                event_label: undefined
            };

            instance.trackEvent(type, name, trackingData);
        };

        /**
         * Track click on external link
         *
         * @param  {string} url         URL of the link
         */
        instance.trackOutboundLink = function(url) {
            var type = instance.GENERIC_EVENT.TYPE;
            var name = instance.GENERIC_EVENT.NAME_OUTBOUND_CLICK;

            var trackingData = {
                event_category: 'outbound click',
                event_action: url,
                event_label: undefined
            };

            instance.trackEvent(type, name, trackingData);
        };

        instance.trackScrollEvent = function(sectionCount, manually) {
            var type = instance.GENERIC_EVENT.TYPE;
            var name = instance.GENERIC_EVENT.NAME_SCROLL_AUTO;
            var label = sectionCount + ' - automatically';
            if (manually === true){
                name = instance.GENERIC_EVENT.NAME_SCROLL_MAN;
                label = sectionCount + ' - manually';
            }

            var trackingData = {
                page_location: window.location.href,
                event_category: 'scroll',
                event_action: window.location.href,
                event_label: label
            };

            instance.trackEvent(type, name, trackingData);
        };

        instance.trackFilterSexualOrientationEvent = function(filterElement) {
            var type = instance.GENERIC_EVENT.TYPE;
            var name = instance.GENERIC_EVENT.NAME_FILTER_SEXUAL_ORIENTATION;
            var trackingData = {
                filter_element: filterElement,
                event_category: 'filter',
                event_action: 'sexual_orientation',
                event_label: filterElement
            };

            instance.trackEvent(type, name, trackingData);
        };

        instance.trackFilterEvent = function(trackingCategory, trackingAction, filterValue){

            var name, event_action, type = instance.GENERIC_EVENT.TYPE;

            if(trackingCategory !== 'product_filter')
            {
                // Only track product_filters
                return;
            }

            switch(trackingAction){
                case 'choose_color':
                    name = instance.GENERIC_EVENT.NAME_FILTER_COLOR;
                    event_action = 'color';
                    break;
                case 'limit_min_price_range':
                    name = instance.GENERIC_EVENT.NAME_FILTER_PRICE_MIN;
                    event_action = 'price min';
                    break;
                case 'limit_max_price_range':
                    name = instance.GENERIC_EVENT.NAME_FILTER_PRICE_MAX;
                    event_action = 'price max';
                    break;
                case 'define_min_rating':
                    name = instance.GENERIC_EVENT.NAME_FILTER_RATING;
                    event_action = 'rating';
                    break;
                case 'choose_brand':
                    name = instance.GENERIC_EVENT.NAME_FILTER_BRAND;
                    event_action = 'brand';
                    break;
                case 'choose_gender':
                    name = instance.GENERIC_EVENT.NAME_FILTER_SEXUAL_ORIENTATION;
                    event_action = 'sexual_orientation';
                    break;
                case 'choose_material':
                    name = instance.GENERIC_EVENT.NAME_FILTER_MATERIAL;
                    event_action = 'material';
                    break;
                case 'choose_size':
                    name = instance.GENERIC_EVENT.NAME_FILTER_SIZE;
                    event_action = 'size';
                    break;
                case 'choose_diameter':
                    name = instance.GENERIC_EVENT.NAME_FILTER_DIAMETER;
                    event_action = 'diameter';
                    break;
                case 'choose_length':
                    name = instance.GENERIC_EVENT.NAME_FILTER_LENGTH;
                    event_action = 'length';
                    break;
                case 'choose_tag':
                    name = instance.GENERIC_EVENT.NAME_FILTER_TAG;
                    event_action = 'tag';
                    break;
                default:
                    return;
            }

            var trackingData = {
                filter_element: filterValue,
                event_category: 'filter',
                event_action: event_action,
                event_label: filterValue
            };

            instance.trackEvent(type, name, trackingData);
        };

        instance.trackDownloadEvent = function(fileName, url) {
            var type = instance.GENERIC_EVENT.TYPE;
            var name = instance.GENERIC_EVENT.NAME_DOWNLOAD;
            var trackingData = {
                file_name: fileName,
                link_url: url,
                event_category:  'downloads',
                event_action: fileName,
                event_label: url
            };

            instance.trackEvent(type, name, trackingData);
        };

        instance.trackSelectItem = function(trackingData){
            var type = instance.SELECT_ITEM_EVENT.TYPE;
            var name = instance.SELECT_ITEM_EVENT.NAME;

            instance.trackEvent(type, name, trackingData);
        };

        instance.trackViewItem = function(trackingData){
            var type = instance.VIEW_ITEM_EVENT.TYPE;
            var name = instance.VIEW_ITEM_EVENT.NAME;
            var location = instance.getPageLocationFromDom();

            instance.trackEvent(type, name, trackingData, location);
        };

        instance.trackPromotionSelect = function(id, promotionName, isTurbo, slot, productData){
            var type = instance.SELECT_PROMOTION_EVENT.TYPE;
            var name = instance.SELECT_PROMOTION_EVENT.NAME;

            var creative_name = '';
            if(isTurbo === true){
                creative_name = 'TRBO';
            }

            var location = instance.getPageLocationFromDom();
            if(typeof location === 'undefined'){
                location = 'unknown';
            }

            var trackingData = {
                video_type: '',
                items: [{
                    promotion_id: id,
                    promotion_name: promotionName,
                    creative_name: creative_name,
                    creative_slot: slot,
                    location_id: location
                }]
            };

            $.extend(trackingData.items[0], productData);

            instance.trackEvent(type, name, trackingData);
        };

        instance.trackPromotionView = function(id, promotionName, isTurbo, slot, productData){
            var type = instance.VIEW_PROMOTION_EVENT.TYPE;
            var name = instance.VIEW_PROMOTION_EVENT.NAME;

            var creative_name = '';
            if(isTurbo === true){
                creative_name = 'TRBO';
            }

            var location = instance.getPageLocationFromDom();
            if(typeof location === 'undefined'){
                location = 'unknown';
            }

            var trackingData = {
                video_type: '',
                items: [{
                    promotion_id: id,
                    promotion_name: promotionName,
                    creative_name: creative_name,
                    creative_slot: slot,
                    location_id: location
                }]
            };

            $.extend(trackingData.items[0], productData);

            instance.trackEvent(type, name, trackingData);
        };

        /**
         * Calls "Track cart event for GA4" but tries to fetch the location before.
         *
         * @param  {string} action          Cart action that is executed in cart (here mapped to GA4 event name)
         * @param  {Object} trackingData    Data to track
         * @param  {int} quantity           Number of affected products
         */
        instance.trackCartEventByGettingLocation = function(action, trackingData, quantity){
            var location = instance.getPageLocationFromDom();
            if(typeof location === 'undefined'){
                location = 'unknown';
            }

            this.trackCartEvent(action, trackingData, location, quantity);
        };

        /**
         * Track cart event for GA4.
         *
         * @param  {string} action          Cart action that is executed in cart (here mapped to GA4 event name)
         * @param  {Object} trackingData    Data to track
         * @param  {string} location        Page the cart event was triggered on
         * @param  {int} quantity           Number of affected products
         */
        instance.trackCartEvent = function(action, trackingData, location, quantity){
            var error = false;
            var type = '', name = '';

            if(typeof quantity !== 'undefined'){
                quantity = Number(quantity);
            }
            else {
                quantity = 1;
            }

            if (typeof trackingData === 'undefined' || trackingData === null){
                trackingData = {};
            }

            if (action === 'add' ){
                // set quantity
                if(_.isArray(trackingData.items)) {
                    _.each(trackingData.items, function (value) {
                        value.quantity = quantity;
                    });
                }
            } else if(action === 'update'){
                // Map "update" action to view or delete and update tracking dat
                var currentQuantity = trackingData.items[0].quantity;
                if(currentQuantity < quantity){
                    action = 'add';
                    trackingData.items[0].quantity = quantity - currentQuantity;
                } else if (currentQuantity > quantity){
                    action = 'delete';
                    trackingData.items[0].quantity = currentQuantity - quantity;
                }
            } else if(action === 'restore'){
                // Map "restore" action to add
                action = "add";
            }

            switch(action){
                case 'view':
                    type = instance.VIEW_CART_EVENT.TYPE;
                    name = instance.VIEW_CART_EVENT.NAME;
                    trackingData.step_number = '1';
                    break;
                case 'add':
                    type = instance.ADD_TO_CART_EVENT.TYPE;
                    name = instance.ADD_TO_CART_EVENT.NAME;
                    break;
                case 'delete':
                    type = instance.REMOVE_FROM_CART_EVENT.TYPE;
                    name = instance.REMOVE_FROM_CART_EVENT.NAME;
                    break;
                case 'delete-permanently':
                    // no tracking needed for GA4
                    return;
                case 'startCheckout':
                    type = instance.START_CHECKOUT_EVENT.TYPE;
                    name = instance.START_CHECKOUT_EVENT.NAME;
                    trackingData.step_number = '2';
                    break;
                default:
                    error = true;
                    // TODO error handler?
                    break;
            }

            if(name === instance.START_CHECKOUT_EVENT.NAME){
                // no tracking for location in start checkout event
                location = null;

            } else {
                // Validate location is accepted (for add and remove):
                switch (location) {
                    case instance.CART_EVENT_LOCATION_PRODUCT_DETAIL_PAGE:
                    case instance.CART_EVENT_LOCATION_CATEGORY_PAGE:
                    case instance.CART_EVENT_LOCATION_SHOPPING_PAGE:
                    case instance.CART_EVENT_LOCATION_HOME_PAGE:
                        break;
                    default:
                        error = true;
                        //console.log("Unknown location: " + location);
                }
            }

            // Remove (delete) differs from other events as the tracking format is already GA4
            if (name !== instance.REMOVE_FROM_CART_EVENT.NAME){
                if(_.isArray(trackingData.items)){
                    _.each(trackingData.items, function(product, key){
                        var price = Number(product.price);
                        trackingData.items[key].price = Number(price.toFixed(2));
                    });
                } else {
                    // Could be empty for a view_cart event with an empty cart
                    if(trackingData.hasOwnProperty('items')) {
                        var price = Number(trackingData.items.price);
                        trackingData.items.price = Number(price.toFixed(2));
                    }
                }
            }

            if (!error){
                instance.trackEvent(type, name, trackingData, location);
            }
        };

        /**
         * Track a (virtual) pageview
         *
         * @param  {String} url The url that should be tracked as a page
         * @param  {String} title The title of the page
         */
        instance.trackVirtualPageView = function(url, title){

            // Build object without name(!)
            var eventObj = {
                'event': instance.VIRTUAL_PAGE_VIEW_EVENT.TYPE,
                'page_path': url,
                'page_title': title
            };

            instance.push(eventObj);
        };

        /**
         * Track overlay
         *
         * @param  {String} url The url the overlay was clicked on
         * @param  {String} title The title of the overlay
         */
        instance.trackOverlay = function(url, title){
            var type = instance.GENERIC_EVENT.TYPE;
            var name = instance.GENERIC_EVENT.NAME_OVERLAY;

            var trackingData = {
                overlay_title: title,
                event_category: 'Overlays',
                event_action: title,
                event_label: url
            };

            instance.trackEvent(type, name, trackingData);
        };

        /**
         * Track javascript errors
         *
         * @param  {String} message error message
         * @param  {String} origin url + linenr + colnr
         * @param  {String} meta additional data like count or type
         */
        instance.trackJavascriptError = function(message, origin, meta){
            var type = instance.JS_ERROR_EVENT.TYPE;
            var name = instance.JS_ERROR_EVENT.NAME;

            var trackingData = {
                javascript_error: {
                    message: message,
                    origin: origin,
                    meta: meta
                }
            };

            instance.trackEvent(type, name, trackingData);
        };

        /**
         * Returns the page location from DOM or returns undefined otherwise.
         */
        instance.getPageLocationFromDom = function() {
            return $('div[data-track-page-location]').data('trackPageLocation');
        };

        init.call(this);

        return instance;
    };

    return new GoogleTrakkenService();
    //@end
});
