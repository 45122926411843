import { registerDynamicView } from 'Jvm/CoreModule/Factory/ViewFactory'; 
import ProductFinderQuestionCollection from './Collection/ProductFinderQuestionCollection';
import ProductFinderAnswerModel from './Model/ProductFinderAnswerModel';

export function init() {
    const productFinderQuestionCollection = new ProductFinderQuestionCollection();
    const productFinderAnswerModel = new ProductFinderAnswerModel();

    registerDynamicView('ProductFinder', () => import('./View/ProductFinderView'), {
        viewParameters: [productFinderQuestionCollection, productFinderAnswerModel]
    });
    registerDynamicView('ProductFinderQuestion', () => import('./View/ProductFinderQuestionView'), {
        viewParameters: [productFinderQuestionCollection, productFinderAnswerModel]
    });
    registerDynamicView('ProductFinderProgress', () => import('./View/ProductFinderProgressView'), {
        viewParameters: [productFinderQuestionCollection]
    });
    registerDynamicView('ProductFinderQuotes', () => import('./View/ProductFinderQuotesView'), {
        viewParameters: [productFinderQuestionCollection]
    });
}
