import { registerDynamicView } from 'Jvm/CoreModule/Factory/ViewFactory'; 
import StickyCollection from './Collection/StickyCollection';
import StickyService from './Service/StickyService';

export function init() {
    const stickyCollection = new StickyCollection();
    StickyService.defineCollection(stickyCollection);
    registerDynamicView('StickyElements', () => import('./View/StickyElementsView'), {
        viewParameters: [stickyCollection]
    });
}
