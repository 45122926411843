define(function(require) {
    "use strict";

    //#import
    var Backbone = require('backbone');
    var _ = require('underscore');

    //@implementation

    /**
     * CoreEvent
     *
     * @author alexander.claes
     */
    var CoreEvent = _.extend({}, Backbone.Events);

    /**
     * Fired when there was a DOM Update
     * @type {String}
     * @constant
     */
    CoreEvent.DOM_UPDATE = 'core:dom-update';

    /**
     * Fired when there was a DOM removal
     * @type {String}
     * @constant
     */
    CoreEvent.DOM_REMOVE = 'core:dom-remove';

    /**
     * Fired when all dynamic views are loaded.
     * @type {string}
     * @constant
     */
    CoreEvent.DOM_DYNAMIC_VIEWS_LOADED = 'core:dom-dynamic-views-loded';

    return CoreEvent;

});
