define(function(require) {
    "use strict";

    //#import
    var BreakpointConstant = require('Jvm/ViewportModule/Constant/BreakpointConstant');
    var BreakpointEvent = require('Jvm/ViewportModule/Event/BreakpointEvent');
    var ResizeEvent = require('Jvm/ViewportModule/Event/ResizeEvent');
    var StylingIndicatorService = require('Jvm/StylingIndicatorModule/Service/StylingIndicatorService');

    //@implementation

    /**
     * BreakpointService
     *
     * @author alexander.claes
     *
     * @constructor
     */
    var BreakpointService = function() {


        /**
         * Instance
         *
         * @type {object}
         */
        var instance = {};

        /**
         * Breakpoints
         *
         * @private
         * @type {object}
         */
        var breakpoints = {};

        /**
         * Current Breakpoint
         *
         * @private
         * @type {string}
         */
        var currentBreakpoint = '';

        /**
         * Last Breakpoint
         *
         * @private
         * @type {string}
         */
        var lastBreakpoint = '';

        // Singleton constructor
        BreakpointService = function BreakpointService() {
            return instance;
        };

        /**
         * Init
         */
        function init() {

            // Map numbers and breakpoints
            breakpoints = {
                1: BreakpointConstant.ONE,
                2: BreakpointConstant.TWO,
                3: BreakpointConstant.THREE,
                4: BreakpointConstant.FOUR,
                5: BreakpointConstant.FIVE,
                6: BreakpointConstant.SIX,
                7: BreakpointConstant.SEVEN
            };

            update();
            //$(window).resize(update);
            ResizeEvent.on(ResizeEvent.CHANGE, update);
        }

        /**
         * Update internal state
         */
        function update() {
            var newBreakpoint = detectBreakpoint();

            // Workaround to fix undefined breakpoint while zooming in Chrome and Opera (EL-825)
            if(typeof newBreakpoint === 'undefined'){
                newBreakpoint = currentBreakpoint;
            }

            if (newBreakpoint !== currentBreakpoint) {
                lastBreakpoint = currentBreakpoint;
                currentBreakpoint = newBreakpoint;

                BreakpointEvent.trigger(BreakpointEvent.CHANGE, currentBreakpoint);
            }
        }

        /**
         * Detect the breakpoint from the width of an indicator element
         * @return {string} The current active breakpoint
         */
        function detectBreakpoint() {
            var tag = 1;

            // if (!breakpointIndicator) {
            //     breakpointIndicator = document.querySelector('.m-breakpoint-indicator');
            // }

            // if (breakpointIndicator) {
            //     tag = breakpointIndicator.clientWidth;
            // }

            tag = StylingIndicatorService.get('breakpoint')

            return breakpoints[tag];
        }

        /**
         * Get a breakpoints name by giving it's number
         * @param  {Int} number A breakpoint's number
         */
        // function getNameByNumber(number) {
        //     return breakpoints[number];

        // }

        /**
         * Get a breakpoints number by giving it's anme
         * @param  {String} name A breakpoint's name
         */
        // function getNumberByName(name) {
        //     for (var idx in breakpoints) {
        //         if (breakpoints[idx] == name) {
        //             return idx;
        //         }
        //     }
        // }

        /**
         * Check if a breakpoint is lower than the current breakpoint
         * @param  {string}  breakpoint The breakpoint that should be tested
         * @return {Boolean}
         */
        instance.isBreakpointLowerThan = function(breakpoint) {
            return currentBreakpoint < breakpoint;
        };

        /**
         * Check if a breakpoint is greater than the current breakpoint
         * @param  {string}  breakpoint The breakpoint that should be tested
         * @return {Boolean}
         */
        instance.isBreakpointGreaterThan = function(breakpoint) {
            return currentBreakpoint > breakpoint;
        };

        /**
         * Check if a breakpoint is equal to the current breakpoint
         * @param  {string}  breakpoint The breakpoint that should be tested
         * @return {Boolean}
         */
        instance.isBreakpointEqualTo = function(breakpoint) {
            return currentBreakpoint === breakpoint;
        };

        instance.getBreakpoint = function() {
            return currentBreakpoint;
        };

        instance.getLastBreakpoint = function() {
            return lastBreakpoint;
        };

        //instance.on = registerObserver;

        //Call Init
        init.call(this);

        return instance;
    };

    return new BreakpointService();
    //@end
});
