define(function(require) {
    "use strict";

    //#import
    var _ = require('underscore');
    var GoogleTrakkenService = require('Jvm/TrackingModule/Service/GoogleTrakkenService');

    //@implementation

    /**
     * JavascriptErrorTrackingService
     *
     * @author stephan.zhang
     *
     * @constructor
     */
    var JavascriptErrorTrackingService = function() {

        /**
         * Instance
         *
         * @type {object}
         */
        var instance = {};

        instance.errorLogTimeoutCue = {};
        instance.invalidErrorLogCue = {};
        instance.initTime = [];

        // Singleton constructor
        JavascriptErrorTrackingService = function JavascriptErrorTrackingService() {
            return instance;
        };

        /**
         * Init
         */
        function init() {
            /**
             * readout the minimal event collection, provided as early as possible in the page(currently -> CMpage.tpl)
             */
            window.aaaJsErrorLogger = window.aaaJsErrorLogger || { events: [] };
            instance.initTime = window.aaaJsErrorLogger.initTime || Date.now();
            window.aaaJsErrorLogger.handled = true;

            _.each(window.aaaJsErrorLogger.events, (event) => {
                instance.processErrorEvent(event);
            });

            window.addEventListener('error', (errorEvent) => {
                instance.processErrorEvent(errorEvent);
            });
            window.addEventListener('unload', (event) => {
                instance.triggerErrorsOnHold();
            });
        }

        /**
         * process event/error data and create tracking data
         */
        instance.processErrorEvent = function(errorEvent) {
            const message = errorEvent.message;
            const filename = errorEvent.filename;
            const lineno = errorEvent.lineno;
            const colno = errorEvent.colno;
            const error = errorEvent.error || {};
            const error_message = error.message || '';

            const errorData = {
                message: message || '',
                origin: filename + ':::' + (lineno ? lineno : 0) + ':' + (colno ? colno : 0),
                meta: {
                    count: 0,
                    durationTimeMs: Date.now() - instance.initTime,
                }
            };

            if (error_message !== message && error_message.length > message.length) {
               errorData.message = error_message;
            }

            if (instance.isValidTrackingEvent(errorData)) {
                instance.cueEvent(errorData);
            } else {
                instance.cueInvalidEvent(errorData);
            }
        };

        /**
         * check if error data is usable and valid to send as a single tracking event
         */
        instance.isValidTrackingEvent = function(errorData) {
            return errorData.message !== '' && errorData.message !== 'Script error.';
        };

        /**
         * for reduced event numbers in ga4, timeout the tracking signal and wait for the same error and increment counter
         */
        instance.cueEvent = function(eventData){
            eventData.cueId = eventData.message + '-' + eventData.origin;
            let cuedEvent = instance.errorLogTimeoutCue[eventData.cueId];
            if (cuedEvent) {
                clearTimeout(cuedEvent.timeout);
            } else {
                cuedEvent = instance.errorLogTimeoutCue[eventData.cueId] = {
                    timeout: null,
                    data: eventData
                };
            }
            cuedEvent.data.meta.count++;
            cuedEvent.timeout = setTimeout(() => { instance.fireEvent(cuedEvent.data); }, 500);
        };

        /**
         * ga4 had too many empty or unusable tracking events. cue them separately and count how many times it popped up
         * they will send before leaving the page
         */
        instance.cueInvalidEvent = function(eventData){
            const cueId = eventData.message + '-' + eventData.origin;
            let cuedEvent = instance.invalidErrorLogCue[cueId];
            if (!cuedEvent) {
                cuedEvent = instance.invalidErrorLogCue[cueId] = eventData;
            }
            cuedEvent.meta.count++;
        };

        /**
         * firing an event from the validEvent cue
         */
        instance.fireEvent = function(eventData){
            delete instance.errorLogTimeoutCue[eventData.cueId];
            GoogleTrakkenService.trackJavascriptError(eventData.message, eventData.origin, JSON.stringify(eventData.meta));
        };

        /**
         * trigger the tracking of the invalid error event
         */
        instance.triggerErrorsOnHold = function(){
            _.each(_.values(instance.invalidErrorLogCue), (eventData) => {
                GoogleTrakkenService.trackJavascriptError(eventData.message, eventData.origin, JSON.stringify(eventData.meta));
            });
        };

        //Call Init
        init.call(this);

        return instance;
    };

    return new JavascriptErrorTrackingService();
    //@end
});
