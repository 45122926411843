define(function(require) {
    "use strict";

    //#import
    var Backbone = require('backbone');
    var _ = require('underscore');

    //@implementation

    /**
     * ScrollEvent
     *
     * @author carina.grabke
     */
    var ScrollEvent = _.extend({}, Backbone.Events);

    /**
     * Fired when window is scrolled
     * @type {String}
     * @constant
     */
    ScrollEvent.SCROLL = 'scroll:scroll';

    ScrollEvent.SCROLL_START = 'scroll:start';

    ScrollEvent.SCROLL_STOP = 'scroll:stop';

    return ScrollEvent;

});
