import { registerDynamicView } from 'Jvm/CoreModule/Factory/ViewFactory';

export function init() {
    registerDynamicView('Cart', () => import('./View/CartView'));
    registerDynamicView('CartMobile', () => import('./View/CartMobileView'));
    registerDynamicView('CartCheckout', () => import('./View/CartCheckoutView'));
    registerDynamicView('CartItem', () => import('./View/CartCheckoutItemView'));
    registerDynamicView('CartCheckoutHermesFinder', () => import('./View/CartCheckoutHermesFinderView'));
    registerDynamicView('CartCheckoutHermesNotification', () => import('./View/CartCheckoutHermesNotificationView'));
    registerDynamicView('CartCheckoutKlarna', () => import('./View/CartCheckoutKlarnaView'));
}
