define(function(require) {
    "use strict";

    //#import

    //@implementation

    /**
     * ListingTeaserHeightSliderTopicsAdapter
     *
     * @author alexander.claes
     *
     * @constructor
     */
    var ListingTeaserHeightSliderTopicsAdapter = function() {


        /**
         * Instance
         * @type {object}
         */
        var instance = {};

       /**
         * The DOM element to read a value
         * @type {object}
         */
        var element;


        // Singleton constructor
        ListingTeaserHeightSliderTopicsAdapter = function ListingTeaserHeightSliderTopicsAdapter() {
            return instance;
        };

        /**
         * Init
         */
        function init() {}

        /**
         * The instance's name to reference this adapter
         * @type {String}
         */
        instance.name = 'listing-teaser-height-sliderTopics';

        /**
         * Get a specific styling setting
         * @return {Integer|null}
         */
        instance.get = function(parentElement){
            var result = null;

            // Make sure the element is defined
            if(!element){
                element = parentElement.querySelector('.indicator-' + instance.name);
            }

            // Read the value
            if(element){
                result = element.clientHeight;
            }

            return result;
        }


        //Call Init
        init.call(this);

        return instance;
    };

    return new ListingTeaserHeightSliderTopicsAdapter();
    //@end
});
