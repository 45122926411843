define(function(require) {
    "use strict";

    //#import
    var Backbone = require('backbone');

    //@implementation

    /**
     * TvChannelModel
     *
     * @author alexander.claes
     *
     * @constructor
     */
    function TvChannelModel() {

        /**
         * Instance
         * @type {Object}
         */
        var instance = {};

        /**
         * Default properties
         * @type {Object}
         */
        instance.defaults = {
            active: false,
            identifier: null
        };

        var Model = Backbone.Model.extend(instance);

        return new Model();
    }

    return TvChannelModel;
    //@end
});
