define(function(require) {
    "use strict";

    //#import
    var Backbone = require('backbone');
    var _ = require('underscore');

    //@implementation

    /**
     * ResizeEvent
     *
     * @author carina.grabke
     */
    var ResizeEvent = _.extend({}, Backbone.Events);

    /**
     * Fired when the window is resized or the orientation on mobile devices changes
     * @type {String}
     * @constant
     */
    ResizeEvent.CHANGE = 'resize:change';

    return ResizeEvent;

});
