define(function(require) {
    "use strict";

    //#import
    var Backbone = require('backbone');
    var _ = require('underscore');

    //@implementation

    /**
     * BreakpointEvent
     *
     * @author alexander.claes
     */
    var BreakpointEvent = _.extend({}, Backbone.Events);

    /**
     * Fired when the brakpoint has been changed
     * @type {String}
     * @constant
     */
    BreakpointEvent.CHANGE = 'breakpoint:change';

    return BreakpointEvent;

});
