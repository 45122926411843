define(function(require) {
    "use strict";

    //#import
    var Backbone = require('backbone');

    //@implementation

    function PartialLoadModel() {

        var instance = {};

        instance.defaults = {
            'loaded': false,
            'scope': null
        };

        var Model = Backbone.Model.extend(instance);

        return new Model();
    }

    return PartialLoadModel;
    //@end
});
