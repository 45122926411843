define(function(require) {
    "use strict";

    //#import
    var Backbone = require('backbone');

    //@implementation

    /**
     * OverlayModel
     *
     * @author carina.grabke
     *
     * @constructor
     */
    function OverlayModel() {

        /**
         * Instance
         * @type {Object}
         */
        var instance = {};

        /**
         * Default properties
         * @type {Object}
         */
        instance.defaults = {
            content: null,
            theme: '',
            themedCover: false,
            ajaxUrl: null,
            tag: false,
            scrollPosition: 0
        };

        var Model = Backbone.Model.extend(instance);

        return new Model();
    }

    return OverlayModel;
    //@end
});
