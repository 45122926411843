define(function(require) {
    "use strict";

    //#import
    var Backbone = require('backbone');
    var TvChannelModel = require('Eis/TvModule/Model/TvChannelModel');

    //@implementation

    /**
     * TvChannelCollection
     *
     * @author alexander.claes
     *
     * @constructor
     */
    function TvChannelCollection() {

        /**
         * Instance
         * @type {Object}
         */
        var instance = {};

        /**
         * Type of models this collection contains
         * @type {Object}
         */
        instance.model = TvChannelModel;

        var Collection = Backbone.Collection.extend(instance);

        return new Collection();
    }

    return TvChannelCollection;
    //@end
});
