define(function(require) {
    "use strict";

    var _ = require('underscore');
    var $ = require('jquery');
    var fastdom = require('fastdom');

    /**
     * ClientUtility
     *
     * very thin and light way Modernizr replacement
     * used only absolutly neccassery data
     *
     * @author marvin.maj [imb]
     *
     * @constructor
     */
    var ClientUtility = function() {

        var instance = {};

        var support_flags = {};

        instance.touchevents = false;

        instance.sessionstorage = false;

        instance.localstorage = false;

        // Singleton
        ClientUtility = function ClientUtility() {
            return instance;
        };

        function init() {
            instance.touchevents = ('ontouchstart' in window);

            try {
                if(typeof(sessionStorage) !== 'undefined') {
                    instance.sessionstorage = true;
                }
            } catch(e) {}

            try {
                if(typeof(localStorage) !== 'undefined') {
                    instance.localstorage = true;
                }
            } catch(e) {}

            support_flags.touchevents = (instance.touchevents)
                ? 'touchevents'
                : 'no-touchevents';
        }

        instance.addCssClassesToElem = function($el) {
            $el.removeClass('no-js').addClass('js');

            _.each(support_flags, function(cssClass) {
                $el.addClass(cssClass);
            });
        };

        instance.readDom = function(func) {
            fastdom.measure(func);
        };

        instance.writeDom = function(func) {
            fastdom.mutate(func);
        };

        init.call(this);

        return instance;
    };

   return new ClientUtility();
});