define(function(require) {
    "use strict";

    //#import

    //@implementation

    /**
     * BreakpointConstant
     *
     * @author alexander.claes
     */
    var BreakpointConstant = {};

    /**
     * The first breakpoint (Size-1 Layout)
     * @type {String}
     * @constant
     */
    BreakpointConstant.ONE = 1;

    /**
     * The second breakpoint (Size-2 Layout)
     * @type {String}
     * @constant
     */
    BreakpointConstant.TWO = 2;

    /**
     * The third breakpoint (Size-3 Layout)
     * @type {String}
     * @constant
     */
    BreakpointConstant.THREE = 3;

    /**
     * The fourth breakpoint (Size-4 Layout)
     * @type {String}
     * @constant
     */
    BreakpointConstant.FOUR = 4;

    /**
     * The fifths breakpoint (Size-5 Layout)
     * @type {String}
     * @constant
     */
    BreakpointConstant.FIVE = 5;

    /**
     * The sixt breakpoint (Size-6 Layout)
     * @type {String}
     * @constant
     */
    BreakpointConstant.SIX = 6;

    /**
     * The seventh breakpoint (Size-7 Layout)
     * @type {String}
     * @constant
     */
    BreakpointConstant.SEVEN = 7;

    return BreakpointConstant;

});
