define(function(require) {
    "use strict";

    //#import
    var Backbone = require('backbone');

    //@implementation

    /**
     * MainNavigationLinkListModel
     *
     * @author alexander.claes
     *
     * @constructor
     */
    function MainNavigationLinkListModel() {

        /**
         * Instance
         * @type {object}
         */
        var instance = {};

        /**
         * Default properties
         * @type {Object}
         */
        instance.defaults = {
            active: false,
            bannerColumn: false
        };

        /**
         * Toggle the model's active state
         */
        instance.toggle = function() {
            this.set('active', !this.get('active'));
        };

        /**
         * Set the model's active state true
         */
        instance.activate = function() {
            this.set('active', true);
        };

        /**
         * Set the model's active state false
         */
        instance.deactivate = function() {
            this.set('active', false);
        };


        var Model = Backbone.Model.extend(instance);

        return new Model();
    }

    return MainNavigationLinkListModel;
    //@end
});
