define(function(require) {
    "use strict";

    //#import
    var BreakpointIndicatorAdapter = require('Jvm/StylingIndicatorModule/Adapter/BreakpointIndicatorAdapter');
    var ListingGutterVerticalAdapter = require('Jvm/StylingIndicatorModule/Adapter/ListingGutterVerticalAdapter');
    var ListingTeaserHeightNormalAdapter = require('Jvm/StylingIndicatorModule/Adapter/ListingTeaserHeightNormalAdapter');
    var ListingTeaserHeightSliderListiclesAdapter = require('Jvm/StylingIndicatorModule/Adapter/ListingTeaserHeightSliderListiclesAdapter');
    var ListingTeaserHeightSliderTopicsAdapter = require('Jvm/StylingIndicatorModule/Adapter/ListingTeaserHeightSliderTopicsAdapter');
    var ListingTeaserHeightTopicAdapter = require('Jvm/StylingIndicatorModule/Adapter/ListingTeaserHeightTopicAdapter');
    var ListingTeaserHeightVideoAdapter = require('Jvm/StylingIndicatorModule/Adapter/ListingTeaserHeightVideoAdapter');
    var ListingTeaserHeightBlogNormalAdapter = require('Jvm/StylingIndicatorModule/Adapter/ListingTeaserHeightBlogNormalAdapter');
    var ListingTeaserHeightBlogSmallAdapter = require('Jvm/StylingIndicatorModule/Adapter/ListingTeaserHeightBlogSmallAdapter');

    //@implementation

    /**
     * StylingIndicatorService
     *
     * @author alexander.claes
     *
     * @constructor
     */
    var StylingIndicatorService = function() {


        /**
         * Instance
         * @type {object}
         */
        var instance = {};

        /**
         * DOM element that contains all indicators
         * @type {object}
         */
        var element;

        /**
         * List of all adapters for specific indicators
         * @type {object}
         */
        var adapters = {};

        /**
         * Init status of the service
         * @type {boolean}
         */
         var isInitialized = false;

        // Singleton constructor
        StylingIndicatorService = function StylingIndicatorService() {
            return instance;
        };

        /**
         * Init
         */
        function init() {

            // Find the parent element for all indicators
            element = document.querySelector('.m-styling-indicator');

            // Add several adapters
            addAdapter(BreakpointIndicatorAdapter);
            addAdapter(ListingGutterVerticalAdapter);
            addAdapter(ListingTeaserHeightNormalAdapter);
            addAdapter(ListingTeaserHeightSliderListiclesAdapter);
            addAdapter(ListingTeaserHeightSliderTopicsAdapter);
            addAdapter(ListingTeaserHeightTopicAdapter);
            addAdapter(ListingTeaserHeightVideoAdapter);
            addAdapter(ListingTeaserHeightBlogNormalAdapter);
            addAdapter(ListingTeaserHeightBlogSmallAdapter);
            isInitialized = true;
        }

        /**
         * Add a new adapter
         */
        function addAdapter(adapter){
            if(adapter.name){
                // Add Adapter to the referece list
                adapters[adapter.name] = adapter;
            }
        }

        /**
         * Get a styling setting
         * @param  {String} setting The properties name to read (equals the adapter's name)
         * @return *
         */
        instance.get = function(setting){
            var result = null;

            // The wrapping DOM element must be set
            if (!isInitialized) {
                init();
            }


            if(element){
                // The adapter must exist
                if(setting && setting.length && adapters[setting]){
                    result = adapters[setting].get(element);
                }
            }


            // console.log('StylingIndicatorService.get() ------>', setting,  result);

            return result;
        }


        //Call Init
        // init.call(this);

        return instance;
    };

    return new StylingIndicatorService();
    //@end
});
