define(function(require) {
    "use strict";

    //#import
    var Backbone = require('backbone');
    var _ = require('underscore');

    //@implementation

    /**
     * MainNavigationEvent
     *
     * @author carina.grabke
     */
    var MainNavigationEvent = _.extend({}, Backbone.Events);

    /**
     * Fired when the navigation should be toggled
     * @type {String}
     * @constant
     */
    MainNavigationEvent.MAINNAVIGATION_TOGGLE = 'mainnavigation:toggle';

    /**
     * Fired when the navigation should be opened
     * @type {String}
     * @constant
     */
    MainNavigationEvent.MAINNAVIGATION_OPEN = 'mainnavigation:open';

    /**
     * Fired when the navigation should be close
     * @type {String}
     * @constant
     */
    MainNavigationEvent.MAINNAVIGATION_CLOSE = 'mainnavigation:close';

    /**
     * Fired when a navigation item is opened
     * @type {String}
     * @constant
     */
    MainNavigationEvent.MAINNAVIGATION_CLOSE = 'mainnavigation:close';

    
    MainNavigationEvent.MAINNAVIGATION_FILL_AJAX_CONTENT = 'mainnavigation:fill-ajax-content';

    MainNavigationEvent.MAINNAVIGATION_REINIT_RESIZE_FEATURES = 'mainnavigation:reinit-resize-features';

    return MainNavigationEvent;

});
