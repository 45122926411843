define(function (require) {
    "use strict";

    const autocompleter = require("autocompleter");
    const _ = require("underscore");

    const DEBOUNCE_IN_MS = 200;

    function AddressSuggestionsView() {
        let $el;
        let config;
        let fields;

        function fetchSuggestions(field) {
            if (field.abortController) {
                field.abortController.abort();
            }

            field.abortController = new AbortController();

            const values = getFieldValues();
            const src = getConfig().src;
            return fetch(src, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    active: field.name,
                    values,
                }),
                credentials: "include",
                signal: field.abortController.signal,
            }).then((response) => response.json(), onFetchSuggestionsError);
        }

        function onFetchSuggestionsError(error) {
            console.log(error);
        }

        this.initialize = function () {
            $el = this.options.element;
            initFields();
        };

        function initFields() {
            fields = [];
            getConfig().options.forEach((option) => {
                const inputElements = document.querySelectorAll(
                    option.selector
                );
                Array.from(inputElements).forEach((inputElement) => {
                    initField(inputElement, option);
                });
            });
        }

        function initField(inputElement, option) {
            const name = option.name || inputElement.name;
            const field = {
                ...option,
                labelTemplateParser: _.template(option.labelTemplate),
                valueTemplateParser: _.template(option.valueTemplate),
                inputElement,
                name,
            };

            inputElement.classList.add('js-address-suggestions-view-input');
            inputElement.addEventListener('focus', (event) => onFieldInputFocus(event, field));
            inputElement.addEventListener('blur', (event) => onFieldInputBlur(event, field));

            autocompleter({
                input: field.inputElement,
                debounceWaitMs: DEBOUNCE_IN_MS,
                disableAutoSelect: true,
                fetch: function (text, update) {
                    fetchSuggestions(field).then((suggestions) => {
                        update(getFieldSuggestions(field, suggestions || []));
                    });
                },
                onSelect: function (suggestion) {
                    updateInputElements(suggestion);
                },
            });

            fields.push(field);
        }

        function onFieldInputFocus(event, field) {
            field.inputElement.setAttribute("autocomplete", "new-password");
        }

        function onFieldInputBlur(event, field) {
            field.inputElement.removeAttribute("autocomplete");
        }

        function updateInputElements(suggestion) {
            fields.forEach((field) => {
                const inputElement = field.inputElement;
                inputElement.value = field.valueTemplateParser(suggestion);
                inputElement.dispatchEvent(new Event("blur", { bubbles: true }));
            });
        }

        function getFieldValues() {
            return fields.reduce((acc, field) => {
                return {
                    ...acc,
                    [field.name]: field.inputElement.value,
                };
            }, {});
        }

        function getFieldSuggestions(field, suggestions) {
            return suggestions.map((suggestion) => {
                return {
                    ...suggestion,
                    label: field.labelTemplateParser(suggestion),
                    value: field.valueTemplateParser(suggestion),
                };
            });
        }

        function getConfigFromDOM() {
            const src = $el.data("src");

            const valueTemplate = ($el.data("valueTemplate") || "").trim();
            const labelTemplate = ($el.data("labelTemplate") || "").trim();
            const queryContainer = $el[0].content || $el[0];
            const optionElements = queryContainer.querySelectorAll("option");
            const options = [];

            for (const optionElement of Array.from(optionElements)) {
                const optionSelector = optionElement
                    .getAttribute("selector")
                    .trim();

                if (!optionSelector) {
                    continue;
                }

                const name = optionElement.getAttribute("name").trim();
                const defaultTemplate = `<%= ${name} %>`;

                const optionValueTemplate =
                    optionElement.getAttribute("value").trim() ||
                    valueTemplate ||
                    defaultTemplate;
                const optionLabelTemplate =
                    getHtmlOfElement(optionElement) ||
                    labelTemplate ||
                    defaultTemplate;

                options.push({
                    name,
                    selector: optionSelector,
                    valueTemplate: optionValueTemplate.trim(),
                    labelTemplate: optionLabelTemplate.trim(),
                });
            }

            return {
                src,
                options,
            };
        }

        function getHtmlOfElement(element) {
            return element.innerHTML
                .replace(/&lt;/g, "<")
                .replace(/&gt;/g, ">")
                .trim();
        }

        function getConfig() {
            if (!config) {
                config = getConfigFromDOM();
            }

            return config;
        }
    }

    return {
        id: "AddressSuggestions",
        object: AddressSuggestionsView,
    };
    //@end
});
