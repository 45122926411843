define(function(require) {
    "use strict";

    //#import

    //@implementation

    /**
     * BreakpointIndicatorAdapter
     *
     * @author alexander.claes
     *
     * @constructor
     */
    var BreakpointIndicatorAdapter = function() {


        /**
         * Instance
         * @type {object}
         */
        var instance = {};

       /**
         * The DOM element to read a value
         * @type {object}
         */
        var element;


        // Singleton constructor
        BreakpointIndicatorAdapter = function BreakpointIndicatorAdapter() {
            return instance;
        };

        /**
         * Init
         */
        function init() {}

        /**
         * The instance's name to reference this adapter
         * @type {String}
         */
        instance.name = 'breakpoint';

        /**
         * Get a specific styling setting
         * @return {Integer|null}
         */
        instance.get = function(parentElement){
            var result = null;

            // Make sure the element is defined
            if(!element){
                element = parentElement.querySelector('.indicator-' + instance.name);
            }

            // Read the value
            if(element){
                result = element.clientWidth;
            }

            return result;
        }


        //Call Init
        init.call(this);

        return instance;
    };

    return new BreakpointIndicatorAdapter();
    //@end
});
