define(function(require) {
    "use strict";

    //#import
    var Backbone = require('backbone');

    //@implementation

    /**
     * MainNavigationBlockModel
     *
     * @author alexander.claes
     *
     * @constructor
     */
    function MainNavigationBlockModel() {

        /**
         * Instance
         * @type {Object}
         */
        var instance = {};

        /**
         * Default properties
         * @type {Object}
         */
        instance.defaults = {
            $element: null,
            container: null,
            index: 0
        };

        /**
         * Set a breakpoint configuration
         * @param {String} breakpoint The breakpoint this config should apply to
         * @param {Object} conf       The confguration for this breakpoint
         */
        instance.setBreakpointConfig = function(breakpoint, conf) {
            this.set('atBreakPoint' + breakpoint, conf);
        };

        /**
         * Get a breakpoint configuration
         * @param  {String} breakpoint The configuration's breakpoint
         * @return {Object}            The configutation for the given breakpoint
         */
        instance.getBreakpointConfig = function(breakpoint) {
            return this.get('atBreakPoint' + breakpoint);
        };



        var Model = Backbone.Model.extend(instance);

        return new Model();
    }

    return MainNavigationBlockModel;
    //@end
});
