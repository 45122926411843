import { registerDynamicView } from 'Jvm/CoreModule/Factory/ViewFactory';
import MainNavigationBlockCollection from './Collection/MainNavigationBlockCollection';
import MainNavigationItemCollection from './Collection/MainNavigationItemCollection';

export function init() {
    const mainNavigationBlockCollection = new MainNavigationBlockCollection();
    const mainNavigationItemCollection = new MainNavigationItemCollection();

    registerDynamicView('MainNavigationBurger', () => import('./View/MainNavigationBurgerView'));
    registerDynamicView('MainNavigationWrapper', () => import('./View/MainNavigationWrapperView'), {
        viewParameters: [mainNavigationBlockCollection, mainNavigationItemCollection]
    });
    registerDynamicView('MainNavigation', () => import('./View/MainNavigationView'), {
        viewParameters: [mainNavigationBlockCollection, mainNavigationItemCollection]
    });
    registerDynamicView('MainNavigationItem', () => import('./View/MainNavigationItemView'), {
        viewParameters: [mainNavigationItemCollection]
    });
    registerDynamicView('MainNavigationContainer', () => import('./View/MainNavigationContainerView'), {
        viewParameters: [mainNavigationBlockCollection]
    });
}
