import { registerDynamicView } from 'Jvm/CoreModule/Factory/ViewFactory'; 
import CrossSellingItemCollection from './Collection/CrossSellingItemCollection';

export function init() {
    const crossSellingCollection = new CrossSellingItemCollection();
    registerDynamicView('ProductDetail', () => import('./View/ProductDetailView'));
    registerDynamicView('ProductDetailShots', () => import('./View/ProductDetailShotsView'));
    registerDynamicView('ProductDetailCrossSellingLoader', () => import('./View/ProductDetailCrossSellingLoaderView'));
    registerDynamicView('ProductDetailStickyControl', () => import('./View/ProductDetailStickyControlView'));
    registerDynamicView('ProductDetailStickyCTA', () => import('./View/ProductDetailStickyCTAView'));
    registerDynamicView('ProductDetailWishlist', () => import('./View/ProductDetailWishlistView'));
    registerDynamicView('ProductDetailCrossSelling', () => import('./View/ProductDetailCrossSellingView'), {
        viewParameters: [crossSellingCollection]
    });
    registerDynamicView('ProductDetailCrossSellingItem', () => import('./View/ProductDetailCrossSellingItemView'), {
        viewParameters: [crossSellingCollection]
    });
}
