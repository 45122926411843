define(function(require) {
    "use strict";

    //#import
    var $ = require('jquery');
    var _ = require('underscore');
    var ResizeEvent = require('Jvm/ViewportModule/Event/ResizeEvent');

    //@implementation

    /**
     * ResizeService
     *
     * @author carina.grabke
     *
     * @constructor
     */
    var ResizeService = function() {


        /**
         * Instance
         *
         * @type {object}
         */
        var instance = {};

        /**
         * Reference to the jquery window object
         * @type {object}
         */
        var $window = null;

        /**
         * Viewport width
         * @return {Number}
         */
        var width = 0;

        /**
         * Viewport height
         * @return {Number} 
         */
        var height = 0;

        // Singleton constructor
        ResizeService = function ResizeService() {
            return instance;
        };

        /**
         * Init
         */
        function init() {

            // Build reference to the window object
            $window = $(window);

            // Save initial window dimensions
            width = $window.width();
            height = $window.height();

            // Refresh on resize or orientationchange
            $window.on('resize orientationchange', resize);
        }

        /**
         * Update internal state
         */
        function resize() {
            var curWidth = $window.width(),
                curHeight = $window.height(),
                widthChange = false,
                heightChange = false;

            // Check if height or widht has been changed
            if (curWidth !== width) {
                widthChange = true;
            }
            if (curHeight !== height){
                heightChange = true;
            }

            // Store new dimensions
            width = curWidth;
            height = curHeight;

            // Trigger change event to notifiy listeners
            ResizeEvent.trigger(ResizeEvent.CHANGE, {
                width: widthChange,
                height: heightChange
            });
        }

        /**
         * Get the window's width
         * @return {Number} The window's width
         */
        instance.getWindowWidth = function(){
            return width;
        };

        /**
         * Get the window's height
         * @return {Number} The window's height
         */
        instance.getWindowHeight = function(){
            return height;
        };


        //Call Init
        init.call(this);

        return instance;
    };

    return new ResizeService();
    //@end
});
